import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "48px 0 48px 0",
	"sm-padding": "60px 0 60px 0",
	"background": "#e2e8f0",
	"quarkly-title": "About-10"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "40%",
			"flex-direction": "column",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "flex-start",
			"lg-margin": "0px 0px 0px 0px",
			"padding": "48px 64px 48px 64px",
			"justify-content": "center",
			"md-padding": "48px 40px 48px 40px",
			"background": "--color-light"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"color": "--dark",
			"font": "--headline2",
			"lg-text-align": "left",
			"sm-font": "--headline3",
			"children": "Maceranıza Bugün Başlayın!"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--dark",
			"font": "--base",
			"lg-text-align": "left",
			"md-margin": "0px 0px 28px 0px",
			"children": "Açık havayı keşfetmeye hazır mısınız? ATV veya UTV'nizi şimdi rezerve edin ve maceranıza Kartepe ATV Safari ile başlayın. Heyecan dolu bir deneyim, muhteşem manzaralar ve unutulmaz anılar vaat ediyoruz. Gecikmeyin - off-road maceranız sizi bekliyor!"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"color": "--lightD1",
			"text-decoration-line": "initial",
			"padding": "7px 24px 8px 24px",
			"background": "--color-primary",
			"font": "--base",
			"transition": "--opacityOut",
			"hover-opacity": ".7",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"border-radius": "4px",
			"children": "Bize Ulaşın"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "60%",
			"justify-content": "flex-start",
			"lg-width": "100%",
			"align-items": "center",
			"lg-margin": "0px 0px 0px 0px",
			"margin": "0px 0px 0px 0px",
			"lg-padding": "0px 0px 0px 0px",
			"lg-justify-content": "center",
			"lg-order": "-1"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://novaverolip.com/images/8.jpg",
			"object-fit": "cover",
			"transform": "translateY(0px)",
			"transition": "transform 0.2s ease-in-out 0s",
			"hover-transform": "translateY(-10px)",
			"max-height": "600px",
			"width": "100%",
			"object-position": "0% 70%",
			"sm-max-height": "500px"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
		</Box>
		<Box {...override("box1")}>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;